<template>
    <ListaStakeholder />
</template>

<script>
import ListaStakeholder from '@/components/Stakeholder/ListaStakeholder'
export default {
  components: {ListaStakeholder},
  data(){
    return{}
  }
}
</script>

<style>

</style>