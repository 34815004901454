<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Stakeholders</h3>
        <p>Listagem de stakeholders</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn" v-if="loading" @click="$router.push('/criar/stakeholder')">
        <v-icon color="#f9f9f2" size='28'>mdi-plus-circle-outline</v-icon>
      </button>
    </div>
    <section class="wrapper-list" >
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          
              <v-select

                        label="Clientes"
                        name="clientes"
                        :items="clientes"
                        item-value="id"
                        item-text="nome"
                        v-model="cliente"
                        @change="[requisitarStakeholder()]"
                   
                       outlined
                        return-object
        ></v-select>
        </v-col>
      </v-row> 
    </section>
    <section class="wrapper-list" v-if="loading">
      <v-data-table 
        :items="items"
        :headers="headers"
        :items-per-page="6"
        @click:row="(item) => $router.push(`/lista/stakeholder/${item.id}`)"
        >

          <template v-slot:[`item.fone`]="{ item }">
            <a style="color:green;font-weight:bold;text-decoration:underline;" :href="'https://wa.me/55'+item.fone" target="_blank">
              {{item.fone}}&nbsp;
              <v-icon small color="green darken-2"  style="margin-bottom:5px;"> mdi-whatsapp </v-icon>  
            </a>
          </template>

      </v-data-table>
    </section>
    <list-skeleton v-if="!loading"/>
  </v-container>
</template> 
<script>
import StakeHolderServico from '@/services/stakeholder.service.js'
import ListSkeleton from '../Loading/ListSkeleton.vue'
import ClienteServico from '@/services/cliente.service.js'
export default {
  components: { ListSkeleton },
  data(){
    return{
      loading: false,
      clientes: [],
      clientesdb: [],
      cliente: null,
      headers: [
        { text: 'Id',value: 'id'},
        { text: 'Cliente', value: 'nomecliente' },
        { text: 'Nome', value: 'nome' },
        { text: 'Email', value: 'email' },
        { text: 'Fone', value: 'fone' },
      ],
      items: [],
    }
  },
  methods:{
    async requisitarStakeholder(){
     
      if(this.cliente){
        this.items = await StakeHolderServico.buscar({idcliente:this.cliente.value}).then(res=> res.data)
      }else{
        this.items = await StakeHolderServico.buscar().then(res=> res.data)
      }
    },
    async requisitarClientes(){
      this.clientes = await ClienteServico.buscar().then(res => {
        this.clientesdb = res.data
        return res.data.map(item => ({nome: item.nome, value: item.id}))
          // return res.data.map(item => item.nome)
      })
      // alert()
    },
  },
  async mounted(){
    await this.requisitarStakeholder()
    await this.requisitarClientes()
    this.loading = true
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 60vw;
  max-width: 100%; 

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.section-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em 0;
}

.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;

  ul{
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #eff3e7;
    padding: 1em;
    width: 100%;

    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 50px;
      background: #eff3e7;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    }
  }
}

.wrapper-list{
  margin: 0 auto;
  width: 60vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
  }

.grow{
  flex: 1;
}

</style>